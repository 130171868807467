'use client';

import { type ReactNode, createContext, useContext } from 'react';
import type { AppConfigValuesType } from '../../config';

interface IConfigContext {
  MAX_FILE_NAME_SIZE?: number;
}

const ConfigContext = createContext<IConfigContext>({});

const ConfigProvider = ({
  config,
  children,
}: {
  config: AppConfigValuesType;
  children: ReactNode;
}) => {
  const parsedConf: IConfigContext = {
    // filters the configs to allow only public ones
    MAX_FILE_NAME_SIZE: config.MAX_FILE_NAME_SIZE,
  };
  return (
    <ConfigContext.Provider value={parsedConf}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;

export const useConfig = () => {
  return useContext(ConfigContext);
};
